* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

canvas {
  overflow: none;
}
.container {
  text-align: center;
}


h2 {
  font-family: Avenir;
  color: rgb(45, 45, 45);
  margin-top: 1.5vh;
  margin-bottom: 0%;
}

h5 {
  font-family: Avenir;
  color: rgb(45, 45, 45);
  margin: 0 0 3vh 0;
}

.nav {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  color: rgb(45, 45, 45);
  font-family: Avenir;
  position: sticky;
  top:0;
  height: 50px;
  z-index: 100;
  width: 100vw;
  border-bottom: 1px solid lightgray;
  
}


.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .8rem 0.5rem .8rem 0.6rem;
  transform: scale(1.75);
}

.nav a.site-title {
  padding: 0.3rem 2.04\rem 0.14rem 2.24rem;
  font-size: 30px;
  font-weight: 10;
}

.nav a.square {
  padding: .8rem 0.8rem .775rem .8rem;
  stroke-width: 1;
}

.nav li{
  border-bottom: 3px solid white;
  list-style: none;
}
.nav li svg{
  fill: none;
  stroke-width: 3;
  stroke: black;
}

.nav li svg.triangle{
  fill: none;
  stroke-width: 2.2;
  stroke: black;
}

.nav li svg.circle{
  fill: none;
  stroke-width: 3.6;
  stroke: black;
}

.nav li svg.particles{
  fill: none;
  stroke-width: 1.6;
  stroke: black;
}

.nav li:hover {
  cursor: pointer;
}


.nav li.active {
  border-bottom: 0.2em solid #e72f01f5;
  transform: none;
}

.nav li.active div {
  animation-name: colorChange;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}
.nav li.active svg {
  animation-name: colorChangeSVG;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}

@keyframes colorChange {
  0%   { background-color:rgb(206, 5, 41); }
  25%  { background-color:rgb(181, 157, 1);}
  50% { background-color:rgb(4, 142, 55);}
  75% { background-color:rgb(55, 4, 142);}
  100% { background-color:rgb(206, 5, 41);}
}

@keyframes colorChangeSVG {
  0%   { fill:rgb(206, 5, 41); }
  25%  { fill:rgb(181, 157, 1);}
  50% { fill:rgb(4, 142, 55);}
  75% { fill:rgb(55, 4, 142);}
  100% { fill:rgb(206, 5, 41);}
}


.nav li:hover svg.triangle{
  /* transform: scale(0.85); */
  transform-origin: 50% 68%;
  animation-name: spin3;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.nav li:hover svg.circle{
  animation-name: spinCircle;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.nav li:hover svg.square{
  animation-name: spin;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  
}

.nav li:hover svg.particles{
  animation-name: turn;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  
}

@keyframes example2 {
  0%   {border-bottom: 1.2rem solid red; }
  25%  {border-bottom: 1.2rem solid yellow; }
  50%  {border-bottom: 1.2rem solid blue; }
  75%  {border-bottom: 1.2rem solid green; }
  100% {border-bottom: 1.2rem solid red; }
}

@keyframes spin {
  0%   {transform:  scale(0.92) rotate(0turn)rotateY(0turn); fill:rgb(206, 5, 41); }
  12.5%   {transform:  scale(0.88) rotate(.125turn)rotateY(-.125turn); fill:rgb(206, 5, 41); }
  25%  {transform:  scale(0.89)rotate(.25turn) rotateY(-.25turn); fill:rgb(181, 157, 1);}
  50% {transform:  scale(0.92)rotate(.5turn) rotateY(-.5turn); fill:rgb(4, 142, 55);}
  75% {transform:  scale(0.89)rotate(.75turn) rotateY(-.75turn); fill:rgb(55, 4, 142);}
  87.5% {transform:  scale(0.88)rotate(.875turn) rotateY(-.875turn); fill:rgb(55, 4, 142);}
  100% {transform: scale(0.92)rotate(1turn) rotateY(-1turn); fill:rgb(206, 5, 41);}
}

@keyframes turn {
  0%   {transform:  scale(0.92) rotate(0turn)rotateY(0turn)rotateX(0turn); fill:rgb(206, 5, 41); }
  12.5%   {transform:  scale(0.88) rotate(-.925turn)rotateY(-.325turn)rotateX(.25turn); fill:rgb(206, 5, 41); }
  25%  {transform:  scale(0.89)rotate(-.55turn) rotateY(-.65turn)rotateX(.5turn); fill:rgb(181, 157, 1);}
  50% {transform:  scale(0.92)rotate(-.85turn) rotateY(-1.95turn)rotateX(1.75turn); fill:rgb(4, 142, 55);}
  75% {transform:  scale(0.89)rotate(-.15turn) rotateY(-1.25turn)rotateX(1turn); fill:rgb(55, 4, 142);}
  87.5% {transform:  scale(0.88)rotate(-1.475turn) rotateY(-1.675turn)rotateX(1.8turn); fill:rgb(55, 4, 142);}
  100% {transform: scale(0.92)rotate(-2turn) rotateY(-2turn)rotateX(3turn); fill:rgb(206, 5, 41);}
}

@keyframes spinCircle {
  0%   {transform:  rotateX(0turn); fill:rgb(206, 5, 41); }
  25%  {transform: rotateX(.25turn); fill:rgb(181, 157, 1);}
  50% {transform: rotateX(.5turn); fill:rgb(4, 142, 55);}
  75% {transform: rotateX(.75turn); fill:rgb(55, 4, 142);}
  100% {transform: rotateX(1turn); fill:rgb(206, 5, 41);}
}
@keyframes spin3 {
  0%   {transform:  rotateY(0turn); fill:rgb(206, 5, 41); }
  25%  {transform: rotateY(.25turn); fill:rgb(181, 157, 1);}
  50% {transform: rotateY(.5turn); fill:rgb(4, 142, 55);}
  75% {transform: rotateY(.75turn); fill:rgb(55, 4, 142);}
  100% {transform: rotateY(1turn); fill:rgb(206, 5, 41);}
}

